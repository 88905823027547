.row-btn .col-12-btn {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

h4,
h5,
h6,
p {
  color: gray !important;
}

hr,
.vl .md-hr {
  color: #595959 !important;
  margin: 6px 0px;
}

hr,
.md-hr {
  border-top: 3px solid #595959;
}

.vl {
  border-left: 3px solid #595959;
}

.col-2-btn,
.col-3-btn,
.col-4-btn,
.col-5-btn,
.col-6-btn {
  padding: 0px 2px !important;
}

.firmware {
  font-size: 0.82em;
  position: relative;
  bottom: 5px;
  font-weight: 500;
  padding: 0px;
  line-height: 2.1em;
}

.right-panel {
  box-shadow: 2px 2px 5px gray;
}

.sm-font {
  font-size: 12px;
}

.loader-icon {
  left: 0;
  top: -100px;
  height: 100%;
  width: 100%;
  text-align: center;
  box-shadow: 2px 2px 5px gray;
  background-color: white;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}