@import '~bootstrap/dist/css/bootstrap.css';
@import './styles/buttons.scss';
@import './styles/shared.scss';
@import './styles/forms.scss';
@import '../fonts/avenir/stylesheet.css';
@import "~angular-notifier/styles";

body {
  overflow-x: hidden;
}



div {
  font-family: 'Avenir';
}

.card-body p {
  font-size: 0.8rem;
  margin-bottom: 0rem;
}

.card-title {
  margin-bottom: 0.25rem !important;
}

.card-body img {
  display: block;
  width: 25%;
  height: auto;
}

.card-body h4 {
  font-weight: 700;
}

.card-body {
  width: 100% !important;
  height: 90%;
}

.card {
  width: 100% !important;
  height: 100%;
}

.card-detail-bm h4 {
  font-weight: 70;
}



.filter-table input {
  font-size: 12px;
}



.right-panel-menu {
  margin-bottom: 10px;
}

.right-panel-menu h1 {
  display: inline;
  text-transform: uppercase;
  font-size: 1.75rem;
}


.right-panel {
  padding: 10px;
}


.table-health-triangle img {
  width: 20px;
  margin-right: 5px;
}


.poweredby {
  text-align: right;
  font-size: 0.8em;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0 !important;
  transform: none;
  border: 1px solid #000;
  border-radius: 8px;
  overflow-y: hidden !important;
}

.custom-dialog-container-no-border-radius .mat-dialog-container {

  padding: 0 !important;
  transform: none;
  border: 1px solid #000;
  overflow-y: hidden !important;
}

.cdk-overlay-container {
  z-index: 2000;
}

.cdk-global-scrollblock {
  top: 0px;
}

.mat-select-search-no-entries-found ng-star-inserted {
  opacity: 0 !important;
}